import { ChatAvatar } from '@/components/chats/ui/ChatAvatar/ChatAvatar'
import { ChatContextMenuContent } from '@/components/chats/ui/ChatContextMenu/ChatContextMenu'
import { ChatName } from '@/components/chats/ui/ChatName/ChatName'
import { Typing } from '@/components/chats/ui/Typing/Typing'
import { getOnlineStatusText } from '@/components/chats/utils'
import { ProfileLink } from '@/components/globalModalLinks/ProfileLink'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ChatModel } from '@/types/models/chat'
import { FullScreen } from '@roolz/icons/chats/FullScreen'
import { SmallScreen } from '@roolz/icons/chats/SmallScreen'
import { Close } from '@roolz/icons/Close'
import { MoreHoriz } from '@roolz/icons/MoreHoriz'
import { MenuList } from '@roolz/sdk/components/ui/MenuList/MenuList'
import { MenuListContent } from '@roolz/sdk/components/ui/MenuList/MenuListContent/MenuListContent'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { ChatType, PcpStatus } from '@roolz/types/api/chats'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { forwardRef, PointerEvent, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MiniChatHeader.module.scss'

interface Props {
  chat: ChatModel

  onPointerDown?: (event: PointerEvent) => void
  onClose: () => void
}

export const MiniChatHeader = observer(function MiniChatHeader({
  chat,
  onPointerDown,

  onClose
}: Props) {
  function Avatar() {
    if(chat.type === ChatType.DIALOG && chat.companion?.id) {
      return (
        <ProfileLink
          profile={chat.companion}
          className={styles.avatar}
        >
          <ChatAvatar
            chat={chat}
            key={chat.id}
          />
        </ProfileLink>
      )
    }

    return (
      <div className={styles.avatar}>
        <ChatAvatar
          chat={chat}
          key={chat.id}
        />
      </div>
    )
  }

  function Name() {
    if(chat.type === ChatType.DIALOG && chat.companion?.id) {
      return (
        <ProfileLink
          profile={chat.companion}
          className={cn(styles.name, styles.nameDialog)}
        >
          <ChatName chat={chat}/>
        </ProfileLink>
      )
    }

    return (
      <div className={styles.name}>
        <ChatName chat={chat}/>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div
        className={styles.content}
        onPointerDown={onPointerDown}
      >
        <Avatar/>

        <div className={styles.content__info}>
          <div className={styles.name__container}>
            <Name/>
          </div>

          <div className={styles.status}>
            <Heading chat={chat}/>
            {/*&nbsp;*/}
          </div>
        </div>

        {/*{chat.count_messages}, {chat.totalMessagesCount}*/}
      </div>

      <div className={styles.actions}>
        <MoreActions chat={chat}/>
        <CloseButton onClick={onClose}/>
      </div>
    </div>
  )
})

const Heading = observer(function Heading({
  chat
}: {
  chat: ChatModel
}) {
  const { t } = useTranslation('chat/common')

  const isTyping = ![ChatType.CHANNEL, ChatType.SELF_CHAT].includes(chat.type)
    ? !!chatSessionsStore.getChatTypingUsers(chat).length
    : null

  if(isTyping) {
    const sender = [ChatType.CHANNEL, ChatType.GROUP_CHAT].includes(chat.type)
      ? profilesStore.findProfile(chatSessionsStore.getChatTypingUsers(chat)?.[0]) || null
      : null

    const senderName = sender ? [
      sender.first_name,
      sender.last_name ? sender.last_name?.substring(0, 1) : undefined
    ].join(' ') : null

    return <>{senderName} <Typing/></>
  }

  if(chat.companion) {
    if(!chat.companion.last_action_time || !chat.companion?.isActive) {
      return null
    }

    return getOnlineStatusText(chat.companion?.onlineStatus, chat.companion.last_action_time, t)
  }

  if([ChatType.GROUP_CHAT, ChatType.CHANNEL].includes(chat.type)) {
    const parts = []

    parts.push(t(
      chat.type === ChatType.CHANNEL
        ? 'subscribers_count'
        : 'members_count',
      { count: chat.count_members })
    )

    if(chatsStore.isChatReady(chat.id)) {
      parts.push(t('users_online', { count: chat.onlineUsersCount })
      )
    }

    return <>{parts.join(', ')}</>
  }

  return null
})

function CloseButton(props: any) {
  return (
    <button
      className={styles.closeButton}
      {...props}
    >
      <Close
        className={styles.closeButton__icon}
      />
    </button>
  )
}

const MoreActions = observer(({
  chat
}: {
  chat: ChatModel
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const buttonRef = useRef<any>()

  if(chat.own_pcp.status !== PcpStatus.ACTIVE) {
    return null
  }

  return (<>
    {!IS_MOBILE && (
      <ExpandButton/>
    )}

    <MoreActionsButton
      ref={buttonRef}
      isActive={open}
    />

    <MenuList
      anchorRef={buttonRef}
      placement='bottom'
      offset={[-10, 0]}
      disablePortal
      onOpenChange={setOpen}

      style={{ zIndex: 1 }}
    >
      <MenuListContent>
        <ChatContextMenuContent chat={chat}/>
      </MenuListContent>
    </MenuList>
  </>)
})

const ExpandButton = observer(() => {
  function toggleExpand() {
    chatSessionsStore.isChatExpanded = !chatSessionsStore.isChatExpanded
  }

  return (
    <button
      className={cn(styles.expandButton, {
        [styles.enabled]: chatSessionsStore.isChatExpanded
      })}
      onClick={toggleExpand}
    >
      {chatSessionsStore.isChatExpanded ? (
        <SmallScreen/>
      ) : (
        <FullScreen/>
      )}
    </button>
  )
})

const MoreActionsButton = forwardRef((props: any, ref: any) => {
  return (
    <button
      className={styles.moreActionsButton}
      ref={ref}
    >
      <MoreHoriz color={props.isActive ? '#2C5BCC' : '#8E8E93'}/>
    </button>
  )
})
