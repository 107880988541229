import { DebugProvider } from '@/components/_debug/DebugProvider'
import { MessagingControlsProvider } from '@/components/chats/MessagingControlsProvider'
import { MiniChatContainer } from '@/components/chats/MiniChatContainer/MiniChatContainer'
import { DefaultErrorBoundary } from '@/components/DefaultErrorBoundary'
import { GlobalRequestModalsContainer } from '@/components/GlobalRequestModalsContainer'
import { PageLoading } from '@/components/layouts/PageLoading/PageLoading'
import { Authed } from '@/components/middlewares/Authed'
import { UpdateAppButton } from '@/components/ui/UpdateAppButton/UpdateAppButton'
import { modalRoutes, resolvePathByName, ROUTE_NAMES, routes as routeMap } from '@/config/routes'
import { ConfirmationContainer } from '@/confirmation/ConfirmationContainer'
import { IdbObservationsProvider } from '@/database/IdbObservationsProvider'
import { GlobalModalsProvider } from '@/global-modals/GlobalModalsProvider'
import { useWindowVisibilityChange } from '@/hooks/events/useWindowVisibilityChange'
import { useLastLocation } from '@/hooks/useLastLocation'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { initClientSettings, settings } from '@/store/client-settings/client-settings'
import { companiesService } from '@/store/companies/companies.service'
import { knowledgeService } from '@/store/knowledge/knowledge.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemService } from '@/store/system/system.service'
import { systemStore } from '@/store/system/system.store'
import { getNow } from '@/utils/date'
import { useServiceWorker } from '@/workers/service-worker/useServiceWorker'
import { WorkersProvider } from '@/workers/WorkersProvider'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@mui/material'
import { defaultTheme } from '@roolz/sdk/config/mui'
import { SdkProvider } from '@roolz/sdk/SdkContext'
import { removeCompanyAfterInvite } from '@roolz/sdk/utils/afterEmailInviteRedirect'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { withProfiler } from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { matchRoutes, useLocation, useNavigate } from 'react-router'
import { useRoutes } from 'react-router-dom'
import './App.scss'
import './plugins/gsap'

// TODO move to somewhere where better place for it
declare global {
  interface Window {
    clipboardData: any
    //Here maps sdk loaded via Here-cdn
    H: any
  }

  const H: any
}

const App = observer(function App() {
  const { t, i18n: { language } } = useTranslation()
  const { waitingWorker, showReload, reloadPage } = useServiceWorker()

  const sdkConfig = {
    t,
    lang: language,
    getServerDatetime: getNow
  }

  useWindowVisibilityChange(() => {
    systemStore.tabVisibility = document.visibilityState

    if(document.visibilityState === 'visible') {
      chatsService.loadOrUpdateChats()
    }
  })

  return (
    <div className='app'>
      <ThemeProvider theme={defaultTheme}>
        <SkeletonTheme baseColor='#EFF1F3'>
          <SdkProvider value={sdkConfig}>
            <DebugProvider/>
            <Authed>
              <IdbObservationsProvider/>
              {settings.lastChatsLoadTime !== null && (
                <WorkersProvider/>
              )}
              <AppInit>
                <MiniChatContainer/>
                {IS_MOBILE && (
                  <MessagingControlsProvider/>
                )}

                {/*<ClientConfigActualizer/>*/}

                <DefaultErrorBoundary>
                  {(!IS_MOBILE || !chatsStore.activeChatId) && (
                    <PagesContainer key={profilesStore.my_profile?.active_space_company_id}/>
                  )}
                </DefaultErrorBoundary>

                {(waitingWorker || showReload) && (
                  <UpdateAppButton onUpdate={reloadPage}/>
                )}

                <ConfirmationContainer/>
              </AppInit>
              {/*<SWProvider/>*/}
            </Authed>
          </SdkProvider>
        </SkeletonTheme>
      </ThemeProvider>
    </div>
  )
})

const PagesContainer = () => {
  const location = useLocation()
  const { saveLastLocation, getLastLocation } = useLastLocation()

  const fallbackLocation = {
    pathname: resolvePathByName(ROUTE_NAMES.MY_OFFERS),
    search: '',
    hash: '',
    state: {},
    key: ''
  }

  const initialLocation = location.state?.backgroundLocation || getLastLocation() || fallbackLocation
  const isMatchModal = matchRoutes(modalRoutes, location)?.length

  useEffect(() => {
    // save only if it's not modal route
    if(!isMatchModal) {
      if(location?.state?.backgroundLocation) {
        saveLastLocation(location?.state?.backgroundLocation)
      } else {
        delete location?.state?.backgroundLocation
        saveLastLocation(location)
      }
    }
  }, [location])

  const Routes = useRoutes(routeMap, isMatchModal ? initialLocation : location)
  const ModalRoutes = useRoutes(modalRoutes)

  return (
    <GlobalModalsProvider>
      {Routes}
      {ModalRoutes}
    </GlobalModalsProvider>
  )
}

function ClientConfigActualizer() {
  const lastDate = useRef(new Date())
  const interval = useRef<any>(null)

  useEffect(() => {
    // Detect when user change date on the device to recalculate client-server UTC date diff
    interval.current = setInterval(() => {
      const timeDiff = new Date().getTime() - lastDate.current.getTime()

      if(Math.abs(timeDiff) >= 5000) { // Five second leniency
        systemService.syncClientConfig()
      }

      lastDate.current = new Date()
    }, 1000)

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return null
}

function AppInit({ children }: {
  children: ReactNode
}) {
  const [dependenciesLoaded, setDependenciesLoaded] = useState<boolean>(false)
  const [ready, setReady] = useState<boolean>(false)


  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if(ready) {
      // @ts-ignore
      window.hideLoader()
    }
  }, [ready])

  useEffect(() => {
    if(dependenciesLoaded) {
      if(!profilesStore.isMyProfileFilled && location.pathname !== resolvePathByName(ROUTE_NAMES.FILL_PROFILE)) {
        /**
         * Remove after invite cookie for new user
         * */
        removeCompanyAfterInvite()
        /**
         * If profile of user have no necessary data in profile, redirect to filling page
         */
        navigate(resolvePathByName(ROUTE_NAMES.FILL_PROFILE))
      } else if(location.pathname === resolvePathByName(ROUTE_NAMES.HOME)) {
        /**
         * Default route
         */
        navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE), { replace: true })
      }

      setTimeout(() => {
        setReady(true)
      })
    }
  }, [location, dependenciesLoaded])

  async function loadDependencies() {
    await initClientSettings()

    const blockingDependencies = [
      systemService.loadCountries(true),
      companiesService.loadMyCompanies()
    ]
    const asyncDependencies = [
      // knowledgeService.loadRequiredDictionaries(),
      knowledgeService.loadRequiredDictionariesList(),
      // knowledgeService.loadExchangeDictionaries(),
      // knowledgeService.loadCompanyDictionaries(),
      knowledgeService.detectUserCountry(),
      chatsService.loadOrUpdateChats()
    ]

    Promise.all(blockingDependencies).then(() => {
      setDependenciesLoaded(true)
    })
    Promise.allSettled(asyncDependencies)
  }

  useLayoutEffect(() => {
    if(profilesStore.isMyProfileFilled) {
      loadDependencies()
    } else {
      Promise.all([
        systemService.loadCountries(true),
        knowledgeService.detectUserCountry()
      ]).then(() => {
        setDependenciesLoaded(true)
      })
    }
  }, [profilesStore.isMyProfileFilled])

  if(!ready) {
    return <PageLoading/>
  }

  return (
    <>{children}</>
  )
}

export default withProfiler(App)
