import { ActingMessage } from '@/components/chats/ui/ChatBottomPanels/NewMessagePanel/ActingMessage/ActingMessage'
import { MAX_MESSAGE_LENGTH } from '@/config/const'
import { isTextMessage, MessageModel } from '@/types/models/chat'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { usePrevious } from 'ahooks'
import { observer } from 'mobx-react-lite'
import { SendMessageButton } from './SendMessageButton/SendMessageButton'
import { TextField } from './TextField/TextField'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { throttle } from 'lodash'
import { ChangeEvent, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
// @ts-ignore
import { v4 as uuidv4 } from 'uuid'
import styles from './NewMessagePanel.module.scss'
import { MessageType, SendMessageRequest, Message } from '@roolz/types/api/chats'

const TYPING_SEND_PERIOD_MS = 5000

interface Props {
  isFocused: boolean

  onMessageSent: () => void
  onActingMessageClick: (msg: MessageModel) => void
}
export const NewMessagePanel = observer(({
  isFocused,

  onMessageSent,
  onActingMessageClick
}: Props) => {
  const fieldRef = useRef<HTMLInputElement>()

  const [content, setContent] = useState<string>(
    chatsStore.activeChat ? chatsStore.activeChat.draft : ''
  )

  function prepareMessage(text: string) {
    return text
      .trim()
      // .replaceAll(/\n\n/g, '\n')
      .substring(0, MAX_MESSAGE_LENGTH)
  }

  const allowSend = !!prepareMessage(content)
    ?.length

  const sendTypingPackage = useCallback(throttle(() => {
    chatsService.sendMyTyping()
  }, TYPING_SEND_PERIOD_MS, {
    trailing: false
  }), [])

  const handleTextChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target?.value ?? '')

    sendTypingPackage()
  }, [])

  const handleSend = useCallback(() => {
    const preparedContent = prepareMessage(content)

    if(!preparedContent?.length) return

    if(chatsStore.activeChat?.id) {
      const message: SendMessageRequest = {
        chat_id: chatsStore.activeChat?.id,
        client_message_id: uuidv4(),
        type: MessageType.TEXT,

        content: JSON.stringify({
          content: {
            text: preparedContent
          }
        })
      }

      chatsService.submitMessage(message)
      onMessageSent()
    }

    setContent('')

    setFocus()
  }, [content])

  const prevMode = usePrevious(chatsStore.activeChatMessagePanelMode)

  useEffect(() => {
    const mode = chatsStore.activeChatMessagePanelMode

    if(mode?.type === 'edit') {
      if(isTextMessage(mode.message)) {
        setContent(mode.message.decodedContent.content.text)
        setFocus()
      }
      // TODO add other types
    } if(mode?.type === 'reply') {
      setFocus()
    } else if(!mode && prevMode?.type === 'edit') {
      setContent('')
    }
  }, [chatsStore.activeChatMessagePanelMode])

  const setFocus = ()=> {
    if(fieldRef.current) {
      fieldRef.current?.focus()
      // Crunch, but without it Replying by clicking appropriate button doesn't set focus
      setTimeout(() => {
        fieldRef.current?.focus()
      }, 50)
    }
  }

  if(IS_MOBILE) {
    const removeFocus = () => {
      if(!mode) {
        if(fieldRef.current) {
          fieldRef.current?.blur()
          // Crunch, but without it Replying by clicking appropriate button doesn't set focus
          setTimeout(() => {
            fieldRef.current?.blur()
          }, 50)
        }
      }
    }

    useLayoutEffect(removeFocus, [])
  }

  useEffect(() => {
    chatsStore.activeChatMessage = content
  }, [content])

  const mode = chatsStore.activeChatMessagePanelMode

  function handlePreventChatAction() {
    if(chatsStore.activeChatId) {
      chatsStore.resetChatMessagePanelMode(chatsStore.activeChatId)
    }
  }

  return (
    <div className={styles.root}>
      {mode && (
        <ActingMessage
          mode={mode.type}
          message={mode.message}
          onAbortAction={handlePreventChatAction}
          onMessageClick={() => onActingMessageClick(mode.message)}
        />
      )}

      <div className={styles.main}>
        <div className={styles.textField}>
          <TextField
            ref={fieldRef}
            isChatFocused={isFocused}

            value={content}
            onChange={handleTextChange}
            onSend={handleSend}
          />
        </div>

        <SendMessageButton
          onClick={handleSend}
          disabled={!allowSend}
        />
      </div>
    </div>
  )
})
