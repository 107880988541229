import {
  MessageDeletedContent
} from '@/components/chats/MessageGroup/Content/MessageDeletedContent/MessageDeletedContent'
import { MessageTextContent } from '@/components/chats/MessageGroup/Content/MessageTextContent/MessageTextContent'
import {
  MessageUnsupportedContent
} from '@/components/chats/MessageGroup/Content/MessageUnsupportedContent/MessageDeletedContent'
import { MessageAttributes } from '@/components/chats/MessageGroup/MessageAttributes/MessageAttributes'
import { BoundedMessagePreview } from '@/components/chats/ui/BoundedMessagePreview/BoundedMessagePreview'
import { ForwardFrom } from '@/components/chats/ui/ForwardedFrom/ForwardFrom'
import { checkIfCanReply } from '@/components/chats/utils'
import { ProfileLink } from '@/components/globalModalLinks/ProfileLink'
import { MessageModelFactory } from '@/models/Message.model'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { isForwardMessage, isReplyMessage, isSystemMessage, isTextMessage, MessageModel } from '@/types/models/chat'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { Message, MessageState } from '@roolz/types/api/chats'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { forwardRef, MutableRefObject, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MessageGroup.module.scss'
import { clearSelection } from '@roolz/sdk/utils/selection'

interface Props {
  isOwn?: boolean
  messages: MessageModel[]
  refsContainer: MutableRefObject<Record<Message['id'], any>>
  highlightedMessageId: Message["id"] | null

  onContextMenu: (message: MessageModel, e: React.MouseEvent<HTMLDivElement>) => void
  onGoToMessage: (message: MessageModel) => void
}

export const MessageGroup = observer(forwardRef(({
  isOwn = false,
  messages,
  refsContainer,
  highlightedMessageId,

  onContextMenu,
  onGoToMessage,
}: Props, ref: any) => {
  const { t, i18n: { language } } = useTranslation('chat/common')

  const firstMessage = messages[0]
  const sender = firstMessage.owner

  const senderName = useMemo(() => {
    if(!sender?.isActive) {
      return t('name.deleted_profile')
    }

    return [
      firstMessage.owner?.first_name,
      firstMessage.owner?.last_name
    ].join(' ')
  }, [language, sender?.first_name, sender?.last_name, sender?.isActive])

  const filteredMessages = useMemo(() => {
    return messages.filter(msg => msg.state !== MessageState.NOT_DISPLAYED)
  }, [messages])

  if(!filteredMessages.length) {
    return null
  }

  return (
    <div
      className={cn(styles.message__block, {
        [styles.message__blockOwn]: isOwn
      })}
      ref={ref}
    >

      {!isOwn && sender && (
        <div className={styles.message__prepend}>
          <ProfileLink profile={sender}>
            <Avatar
              is_deleted={!sender.isActive}
              first_name={sender?.first_name ?? ''}
              last_name={sender?.last_name ?? ''}
              avatarUrl={sender?.avatar}
              color_code={sender?.color ?? ''}
              width={32}
              onlineStatus={sender?.onlineStatus}
            />
          </ProfileLink>
        </div>
      )}

      {/*<div className={styles.message__wrapper}></div>*/}
      <div
        className={cn(styles.message__main, {
          [styles.message__mainOwn]: isOwn
        })}
      >
        {!isOwn && sender && (
          <ProfileLink
            profile={sender}
            className={styles.message__name}
          >
            {senderName}
          </ProfileLink>
        )}

        {filteredMessages.map((message, i) => (
          <MessageContent
            isHighlighted={highlightedMessageId === message.id}
            onContextMenu={onContextMenu}
            onGoToMessage={onGoToMessage}

            ref={(currentRef) => {
              refsContainer.current[message.number] = currentRef
            }}
            // cleanUp={() => {
            //   if(message.status < MessageStatus.SENT) {
            //     console.log('clean up ')
            //     delete refsContainer.current[message.number]
            //   }
            // }}
            key={message.number}

            isOwn={isOwn}
            isFirst={i === 0}
            isLast={i === messages.length - 1}
            message={message}
          />
        ))}
      </div>
    </div>
  )
}))

const MessageContent = observer(forwardRef(function MessageContent({
  isOwn,
  message,
  isHighlighted = false,

  isFirst,
  isLast,

  onContextMenu,
  onGoToMessage
  // cleanUp
}: {
  isOwn: boolean
  message: MessageModel
  isHighlighted?: boolean

  isFirst: boolean
  isLast: boolean,

  onContextMenu: (message: MessageModel, event: React.MouseEvent<HTMLDivElement>) => void
  onGoToMessage: (message: MessageModel) => void
}, ref: any) {
  const contentRef = useRef<any>()
  const prependRef = useRef<any>()

  const Content = useMemo(() => {
    if(message.state === MessageState.DELETED) {
      return <MessageDeletedContent/>
    }

    if(isTextMessage(message)) {
      return (
        <MessageTextContent
          content={message.decodedContent.content}
          attributes={
            <MessageAttributes
              showStatus={isOwn}
              message={message}
            />
          }
        />
      )
    }

    return <MessageUnsupportedContent/>
  }, [message, message.state, isOwn, message?.decodedContent, message.version])

  const ContentPrepend = useMemo(() => {
    if(message.state === MessageState.DELETED) {
      return null
    }

    if(isReplyMessage(message)) {
      // TODO probably on receive message need to check if profiles is loaded and load if not
      const profile = profilesStore.findProfile(message.reply_to.sender_id)

      const title = [profile?.first_name, profile?.last_name].join(' ')
      const replyModel = MessageModelFactory(message.reply_to)
      return (
        <BoundedMessagePreview
          title={title}
          message={MessageModelFactory(message.reply_to)}

          onClick={() => onGoToMessage(replyModel)}
        />
      )
    }

    if(isForwardMessage(message)) {
      return (
        <ForwardFrom
          forwardedMessage={message.forward_from}
        />
      )
    }
  }, [message, message.state])

  if(message.state === MessageState.NOT_DISPLAYED) {
    return null
  }

  function getOrderDependentClasses() {
    if(isOwn) {
      if(isFirst) return styles.contentOwnFirst
      if(isLast) return styles.contentOwnLast
      return styles.contentOwnMiddle
    }

    if(isLast) return styles.contentLast
    if(isFirst) return styles.contentFirst
    return styles.contentMiddle
  }

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    if(IS_MOBILE) {
      if(
        (!prependRef.current || !prependRef.current?.contains(event.target))
        && event.target !== prependRef.current
        && message.state === MessageState.ACTIVE
        && !isSystemMessage(message)
        && event.button === 0
      ) {
        const selected = chatSessionsStore.selectedMessage

        if(selected?.id === message.id) {
          chatSessionsStore.selectedMessage = null
        } else {
          chatsStore.drafts[chatsStore.activeChatId] = chatsStore.activeChatMessage
          chatSessionsStore.selectedMessage = message
        }
      }
    }

    if(IS_MOBILE || !checkIfCanReply(message) || !message || !chatsStore.activeChatId) {
      return
    }
    const DOUBLE_CLICK_NUMBER = 2

    // console.log(ref.current.contains(event.target), ref.current === event.target)

    if(event.detail === DOUBLE_CLICK_NUMBER
      // Check that click was outside message content
      && !contentRef.current.contains(event.target)
      && contentRef.current !== event.target
    ) {
      chatsStore.setReplyingMessage(chatsStore.activeChatId, message)

      event.preventDefault()
      clearSelection()
    }
  }

  return (
    <div
      className={cn(styles.content__wrapper, {
        [styles.content__wrapperOwn]: isOwn,
        [styles.content__wrapperHighlighted]: isHighlighted
      })}
      onClick={handleClick}
      ref={ref}
      onContextMenu={event => {
        event.preventDefault()
        onContextMenu(message, event)
      }}
    >
      <div
        className={cn(styles.content, getOrderDependentClasses(), {
          [styles.contentOwn]: isOwn
        })}
        ref={contentRef}
      >
        {ContentPrepend && (
          <div
            className={styles.content__prepend}
            ref={prependRef}
          >
            {ContentPrepend}
          </div>
        )}
        {Content}
      </div>
    </div>
  )
}))
