import { MessagesWindow } from '@/components/chats/MessagesWindow/MessagesWindow'
import { MiniChatHeader } from '@/components/chats/MiniChat/MiniChatHeader/MiniChatHeader'
import { JoinPanel } from '@/components/chats/ui/ChatBottomPanels/JoinPanel/JoinPanel'
import { MessageActions } from '@/components/chats/ui/ChatBottomPanels/MessageActions/MessageActions'
import { MutePanel } from '@/components/chats/ui/ChatBottomPanels/MutePanel/MutePanel'
import { NewMessagePanel } from '@/components/chats/ui/ChatBottomPanels/NewMessagePanel/NewMessagePanel'
import { ChatDialogActionsPanel } from '@/components/chats/ui/ChatDialogActionsPanel/ChatDialogActionsPanel'
import { ChatLoading } from '@/components/chats/ui/ChatLoading/ChatLoading'
import { checkIfProfileCanWriteToChat } from '@/components/chats/utils'
import { chatSessionsStore } from '@/store/chats/chat-sessions.store'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ChatModel, MessageModel } from '@/types/models/chat'
import { ClickAwayListener } from '@mui/material'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars/snackbars'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { ChatType, PcpStatus } from '@roolz/types/api/chats'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { forwardRef, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './MiniChat.module.scss'
import { IS_MOBILE } from '@roolz/sdk/utils/device'

interface Position {
  left: number
  bottom: number
}

interface Props {
  chat: ChatModel

  width: number
  height: number
  minHeight: number
  position: Position
  className?: string

  onClose: () => void
  onPositionChange: (pos: Position) => void
  onSizeChange: (size: { width: number, height: number }) => void
}

export const MiniChat = observer(forwardRef(({
  chat,

  position,
  width,
  height,
  minHeight,
  className,

  onClose,
  onSizeChange,
  onPositionChange
}: Props, ref: any) => {
  const messagesWindowRef = useRef<any>()

  const [isFocused, setIsFocused] = useState(true)

  const isChatReady = chatsStore.isChatReady(chat.id)

  const [drag, setDrag] = useState<boolean>(false)
  // const pos = position

  const posRef = useStateRef(position)

  const lastX = useRef<number | null>(null)
  const lastY = useRef<number | null>(null)

  const widthRef = useStateRef(width)
  const heightRef = useStateRef(height)
  const minHeightRef = useStateRef(minHeight)

  useEffect(() => {
    if(chatsStore.forwardingMessage) {
      setIsFocused(false)
    }
  }, [chatsStore.forwardingMessage])

  const handleMove = useCallback((event: PointerEvent) => {
    const pos = posRef.current

    if(lastX.current !== null && lastY.current !== null) {
      const deltaX = lastX.current - event.clientX
      const deltaY = lastY.current - event.clientY

      const newPos = {
        ...pos,
        left: Math.min(
          Math.max(0, pos.left - deltaX),
          window.innerWidth - width
        )
      }

      onPositionChange(newPos)
      onSizeChange({
        width: widthRef.current,
        height: Math.min(
          Math.max(minHeightRef.current, heightRef.current + deltaY),
          window.innerHeight
        )
      })

    }

    lastX.current = event.clientX
    lastY.current = event.clientY
  }, [width, height])

  const handlePointerUp = useCallback(() => {
    window.removeEventListener('pointermove', handleMove)
    window.removeEventListener('pointerup', handlePointerUp)
    window.removeEventListener('pointerleave', handlePointerUp)
    setDrag(false)
    lastX.current = null
    lastY.current = null
  }, [])

  const handlePointerDown = useCallback((event: MouseEvent) => {
    if(IS_MOBILE || event.button !== 0 || chatSessionsStore.isChatExpanded) {
      return
    }

    setDrag(true)
    window.addEventListener('pointermove', handleMove)
    window.addEventListener('pointerleave', handlePointerUp)
    window.addEventListener('pointerup', handlePointerUp)

    return () => {
      window.removeEventListener('pointermove', handleMove)
      window.removeEventListener('pointerleave', handlePointerUp)
      window.removeEventListener('pointerup', handlePointerUp)
    }
  }, [])

  const handleMessageSent = useCallback(() => {
    setTimeout(() => {
      if(messagesWindowRef.current) {
        messagesWindowRef.current.goToEnd()
      }
    }, 10)
  }, [])


  function handleClickInside() {
    setIsFocused(true)
  }

  function handleClickAway() {
    setIsFocused(false)
  }

  function handleActingMessageClick(msg: MessageModel) {
    if(messagesWindowRef.current) {
      if(msg.chat_id === chatsStore.activeChatId) {
        messagesWindowRef.current.goToMessage(msg)
      }
    }
  }

  function setSizeToVisualViewport() {
    onSizeChange({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // if(IS_MOBILE && window.visualViewport) {
  //   useEventListener('resize', setSizeToVisualViewport, {
  //     // @ts-ignore
  //     target: window.visualViewport
  //   })
  //
  //   useEffect(() => setSizeToVisualViewport(), [])
  // }

  const pos = !chatSessionsStore.isChatExpanded
    ? posRef.current
    : { bottom: 0, right: 0 }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        className={cn(styles.chat, className, {
          [styles.chatMobile]: IS_MOBILE,
          [styles.chatExpanded]: chatSessionsStore.isChatExpanded
        })}
        style={{
          width,
          height,
          ...pos
        }}
        ref={ref}
        onClick={handleClickInside}
      >
        <div
          className={cn(styles.header, {
            [styles.headerMoving]: drag
          })}
        >
          <MiniChatHeader
            chat={chat}
            onPointerDown={handlePointerDown}
            onClose={onClose}
          />
        </div>


        <ChatDialogActionsPanel chat={chat}/>

        {isChatReady ? (<>

          {/*<div className={styles.messages}>*/}
          <MessagesWindow
            chat={chat}
            ref={messagesWindowRef}
          />
          {/*</div>*/}

          <div className={styles.bottomAction}>
            {/*<NewMessagePanel/>*/}
            <BottomActionPanel
              chat={chat}
              isFocused={isFocused}
              onMessageSent={handleMessageSent}
              onActingMessageClick={handleActingMessageClick}
            />
          </div>
        </>) : (
          <ChatLoading/>
        )}
      </div>
    </ClickAwayListener>
  )
}))

// TODO check logic
const BottomActionPanel = observer(({
  chat,
  isFocused,
  onMessageSent,
  onActingMessageClick
}: {
  chat: ChatModel,
  isFocused: boolean,
  onMessageSent: () => void
  onActingMessageClick: (message: MessageModel) => void
}) => {
  const { t } = useTranslation('chat/common')

  const [isJoining, setIsJoining] = useState<boolean>(false)

  const handleChatJoin = useCallback(() => {
    // TODO Implement
    // Need to think how to sync status between all buttons (here and in ctx menus)

    setIsJoining(true)
    chatsService.joinChat(chat.id)
      .catch(({ response }) => {
        const message = response?.data?.detail
        if(message) {
          toastError(message)
        }

        toastWarning(t('errors:insufficient_request'))
      })
      .finally(() => {
        setIsJoining(false)
      })
  }, [chat])

  const handleChangeMute = useCallback((isMuted: boolean) => {
    if(isMuted) {
      chatsService.muteChat(chat.id)
    } else {
      chatsService.unmuteChat(chat.id)
    }
  }, [chat])


  if(chatSessionsStore.selectedMessage) {
    return (
      <MessageActions
        message={chatSessionsStore.selectedMessage}
      />
    )
  }

  if(profilesStore.my_profile
    && checkIfProfileCanWriteToChat(profilesStore.my_profile, chat)) {
    return (
      <NewMessagePanel
        isFocused={isFocused}
        onMessageSent={onMessageSent}

        onActingMessageClick={onActingMessageClick}
      />
    )
  }

  if(chat.type === ChatType.GROUP_CHAT) {
    if(chat.own_pcp?.status === PcpStatus.GONE) {
      return (
        <JoinPanel
          chatType={chat.type}
          onJoin={handleChatJoin}
        />
      )
    }
  }

  if(chat.type === ChatType.CHANNEL) {
    if(chat.own_pcp?.status === PcpStatus.GONE) {
      return (
        <JoinPanel
          chatType={chat.type}
          onJoin={handleChatJoin}
        />
      )
    } else if(chat.own_pcp?.status === PcpStatus.ACTIVE) {
      return (
        <MutePanel
          isMuted={chat.own_pcp.is_muted}
          onChange={handleChangeMute}
        />
      )
    }
  }

  return null
})
