import { CLIENT_MESSAGES, WORKER_MESSAGES } from '@/config/workers'
import { authStore } from '@/store/auth/auth.store'
import { useState, useEffect, useCallback, useRef } from 'react'
import AppWorker from './app-worker.client'
// @ts-ignore
import heartbeatFactory from "heartbeatjs"

export let appWorker: AppWorker | null = null
let heartbeat: any

export const useAppWorker = () => {
  const [activeWorker, setActiveWorker] = useState<AppWorker | null>(null)
  const msgHandler = useRef<any>()

  const handleWorkerMessage = (msg: any) => {
    if(msg.data.type === WORKER_MESSAGES.PONG) {
      if(heartbeat) {
        heartbeat.receivedPong()
      }
      return
    }

    msgHandler.current?.(msg.data)
  }

  const createWorker = useCallback(() => {
    heartbeat?.stop?.()

    if(appWorker) {
      appWorker?.terminate()
      console.log('STOP AW')

      appWorker = null
      setActiveWorker(null)
    }

    const worker = new AppWorker()
    worker.start()

    worker.onerror = createWorker
    worker.onmessage = handleWorkerMessage

    setActiveWorker(worker)
    appWorker = worker

    return () => {
      heartbeat?.stop?.()
      appWorker?.close()
    }
  }, [appWorker])

  useEffect(createWorker, [authStore.access_token])

  useEffect(() => {
    if(activeWorker) {
      heartbeat = heartbeatFactory({
        timeout: 5000,
      })
      heartbeat.onTimeout(() => {
        console.log('PING FROM AW TIMED OUT')
        heartbeat?.stop()
        createWorker()
      })

      heartbeat.start(() => {
        console.log('SEND PING TO AW')
        activeWorker.postMessage({ type: CLIENT_MESSAGES.PING })
      })
    }
  }, [activeWorker])

  function subscribeOnMessage(cb: (data: any) => void) {
    msgHandler.current = cb
  }

  return { appWorker: activeWorker, subscribeOnMessage }
}
