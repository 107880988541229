import { NewMessagesCount } from '@/components/chats/ui/NewMessagesCount/NewMessagesCount'
import { profilesStore } from '@/store/profiles/profiles.store'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { ChatType } from '@roolz/types/api/chats'
import { ChatModel } from '@/types/models/chat'
import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect, useState } from 'react'
import * as React from 'react'
import styles from './ChatAvatar.module.scss'
import cn from 'classnames'
import { Bookmark } from '@roolz/icons/chats/Bookmark'
import { OnlineStatus } from '@roolz/types/custom'

interface Props {
  chat: ChatModel

  children?: ReactNode
  // showUnreadMessagesCount?: boolean
}

export const ChatAvatar = observer(function ChatAvatar({
  chat,

  children

  // showUnreadMessagesCount
}: Props) {
  if(chat.type === ChatType.SELF_CHAT) {
    return (
      <SelfChatAvatar/>
    )
  }
  if(chat.type === ChatType.DIALOG) {
    return (
      <Avatar
        is_deleted={!chat.companion?.isActive}
        first_name={chat.companion?.first_name ?? ''}
        last_name={chat.companion?.last_name ?? ''}
        avatarUrl={chat.companion?.avatar ?? ''}
        color_code={chat.companion?.color ?? ''}
        width={40}
        onlineStatus={chat.companion?.isActive ? chat.companion?.onlineStatus : undefined}
      >
        {children}
      </Avatar>
    )
  }

  if([ChatType.GROUP_CHAT, ChatType.CHANNEL].includes(chat.type)) {
    // TODO change name forming
    return (
      <Avatar
        first_name={chat?.name ?? ''}
        last_name={''}
        avatarUrl={chat.avatar ?? ''}
        color_code={chat.color ?? ''}
        width={40}
        onlineStatus={chat.companion?.onlineStatus}
      >
        {children}
      </Avatar>
    )
  }

  return null
})

function SelfChatAvatar() {
  return (
    <div className={styles.self}>
      <Bookmark/>
    </div>
  )
}
