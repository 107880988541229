import styles from './NewMessagesCount.module.scss'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export const NewMessagesCount = observer(function NewMessagesCount({
  count,
  isMuted = false,
  withBorder = false
}: {
  count: number,
  isMuted?: boolean

  withBorder?: boolean
}) {
  let cnt: number | string = count
  if(cnt >= 1000) cnt = '999+'
  if(cnt <= 0) cnt = ''

  return (
    <span
      className={cn(styles.newMessagesCount, {
        [styles.newMessagesCount__muted]: isMuted,
        [styles.newMessagesCount__withBorder]: withBorder
      })}
    >
      {cnt}
    </span>
  )
})
