import { isTextMessage, MessageModel } from '@/types/models/chat'
import { ReactNode, useMemo, MouseEvent } from 'react'
import styles from './BoundedMessagePreview.module.scss'
import cn from 'classnames'
interface Props {
  message: MessageModel
  title: ReactNode

  className?: string

  onClick?: (e: MouseEvent<HTMLDivElement>) => void
}
export const BoundedMessagePreview = ({
  title,
  message,
  className,
  onClick
}: Props) => {
  const content: string = useMemo(() => {
    if(isTextMessage(message)) {
      return message.decodedContent.content.text
    }

    // TODO add other types
    return ''
  }, [message])

 return (
   <div
     className={cn(styles.preview, className)}
     onClick={onClick}
   >
     <div className={styles.title}>
       {title}
     </div>

     <div
       className={styles.messageContent}
       title={content}
     >
       {content}
     </div>
   </div>
 )
}
