import { TextMessageContent } from '@roolz/types/api/chats'
import { formattedTextToHtml } from '@roolz/sdk/utils/formatting'
import { observer } from 'mobx-react-lite'
import { Fragment, ReactNode, useEffect } from 'react'
import styles from './MessageTextContent.module.scss'
import mailgo from 'mailgo'

interface Props {
  content: TextMessageContent
  attributes?: ReactNode
}

export const MessageTextContent = observer(({ content, attributes }: Props) => {
  useEffect(() => {
    mailgo()
  }, [])

  const text = formattedTextToHtml(content.text.trim())

  return (
    <div className={styles.content}>
      <span
        dangerouslySetInnerHTML={{ __html: text }}
      />

      <div className={styles.attributes}>
        {attributes}
      </div>
    </div>
  )
})
